<template>
  <div class="city-overview pa-3">
    <v-row>
      <v-col cols="12" sm="7">
        <weatherCard :districtId="tagId" class="header-card" />
      </v-col>
      <v-col cols="12" sm="5">
        <energyCard :data="eneryData" class="header-card" />
      </v-col>
    </v-row>
    <v-row align="end">
      <v-col cols="12" sm="7">
        <span class="font-weight-bold title accent--text">能源地圖</span>
      </v-col>
      <v-col cols="12" sm="5">
        <v-row>
          <v-col cols="auto">
            <span class="font-weight-bold title accent--text" v-if="pageData"
              >{{ pageData.displayName }}節電排名</span
            >
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <schoolTypeFilterCard
              v-model="distRankingQuery"
              @change="getSchoolRanking()"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-card elevation="0" color="#FFFFFF99">
          <v-card-text>
            <v-row>
              <v-col>
                <v-row align="center">
                  <v-col cols="12" md="auto">
                    <TopRankInfo v-model="firstDistData" />
                  </v-col>
                  <v-col>
                    <div class="text-center">
                      <gMap
                        :ranking="distRankingData"
                        :mapCenter="
                          distRankingData[0]
                            ? {
                                lat: distRankingData[0].latitude,
                                lng: distRankingData[0].longitude
                              }
                            : {
                                lat: 0,
                                lng: 0
                              }
                        "
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="5" style="border-left: 1px solid #dddddd88">
                <PowerSavingRanking :ranking="distRankingData" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import weatherCard from './weatherCard'
import energyCard from '@/components/energyCard'
import PowerSavingRanking from '@/components/PowerSavingRanking'
import schoolTypeFilterCard from '@/components/schoolTypeFilterCard'
import gMap from '@/components/gMap'
import TopRankInfo from '@/components/TopRankInfo'
import Competition from '@/api/ems/Competition'
import Meter from '@/api/ems/Meter'

export default {
  name: undefined,
  mixins: [],
  components: {
    weatherCard,
    energyCard,
    PowerSavingRanking,
    schoolTypeFilterCard,
    gMap,
    TopRankInfo
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      distRankingQuery: {
        type: []
      },
      distRankingData: [],
      eneryData: {}
    }
  },
  computed: {
    ...mapGetters({
      pageData: 'page/pageData',
      schoolType: 'config/schoolType'
    }),
    firstDistData() {
      const firstDist = this.distRankingData.find((dist) => dist.ranking === 1)
      return firstDist || {}
    }
  },
  watch: {
    tagId() {
      this.init()
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init() {
      this.distRankingQuery.type = this.schoolType.map((type) => type.key)
      this.getSchoolRanking()
      this.getEnery()
    },
    async getSchoolRanking() {
      try {
        const { data } = await Competition.districtSchool(
          this.tagId,
          this.distRankingQuery
        )
        this.distRankingData = data
      } catch (error) {
        console.error(error)
      }
    },
    getEnery() {
      Meter.district(this.tagId).then((response) => {
        if (!response.data) {
          return
        }
        this.eneryData = response.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.city-overview::v-deep {
  .header-card.v-card {
    height: 360px;
  }
}
</style>
