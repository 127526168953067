<template>
  <div>
    <div ref="googleMapApp" class="googleMap" />
  </div>
</template>

<script>
import gmapsInit from '@/shared/googleMap/gmaps'

export default {
  name: 'GMap',
  mixins: [],
  components: {},
  props: {
    mapZoom: {
      type: Number,
      default: 12
    },
    mapCenter: {
      type: Object,
      default: () => ({
        lat: 25.0771361,
        lng: 121.5548753
      })
    },
    ranking: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      map: null,
      schoolIcon: require('@/assets/image/Component 24 – 22.svg'),
      markers: [],
      openInfowindow: null
    }
  },
  computed: {},
  watch: {
    ranking() {
      this.initMap()
    }
  },
  created: function () {},
  mounted: function () {
    this.initMap()
  },
  destroyed() {},
  methods: {
    async initMap() {
      try {
        const google = await gmapsInit()
        this.map = new google.maps.Map(this.$refs.googleMapApp, {
          zoom: this.mapZoom,
          center: {
            lat: Number(this.mapCenter.lat),
            lng: Number(this.mapCenter.lng)
          },
          streetViewControl: false,
          FullscreenControlOptions: false,
          mapTypeControl: false,
          scaleControl: false,
          minZoom: 11
        })
        this.makeMark()
      } catch (error) {
        console.error(error)
      }
    },
    makeMark() {
      this.removeMarker()
      this.ranking.map((marker) => {
        const thisMarker = new google.maps.Marker({
          icon: this.schoolIcon,
          map: this.map,
          position: {
            lng: Number(marker.longitude),
            lat: Number(marker.latitude)
          }
        })
        thisMarker.addListener('click', () => {
          console.log(marker)
          this.commitValue(marker)
          this.markers.forEach((_marker) => {
            _marker.setAnimation(null)
          })
          thisMarker.setAnimation(google.maps.Animation.BOUNCE)
        })
        this.markers.push(thisMarker)

        return thisMarker
      })
    },
    removeMarker() {
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null)
        google.maps.event.clearInstanceListeners(this.markers[i])
      }
      this.markers = []
    },
    commitValue(value) {
      console.log('commitValue', value)
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.googleMap::v-deep {
  height: 380px;
  width: 100%;

  .gm-style-iw,
  .gm-style-iw-d {
    max-height: 300px !important;
  }
  .gm-style-iw-d {
    overflow: hidden !important;
  }
  .gm-style-iw-c {
    background-color: #00000077;
    color: white;
    border-radius: 3px;
  }
  .gm-style-iw-t::after {
    background: linear-gradient(
      45deg,
      #0000003a 50%,
      rgba(255, 255, 255, 0) 51%,
      rgba(255, 255, 255, 0) 100%
    );
    box-shadow: none;
  }
}
</style>
