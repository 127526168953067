<template>
  <v-menu
    offset-y
    v-model="filterMenu"
    :close-on-content-click="false"
    :close-on-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="accent"
        elevation="0"
        v-bind="attrs"
        v-on="on"
        @click="resetQuery()"
        class="px-2"
        style="min-width: unset"
      >
        <v-icon color="secondary">mdi-filter</v-icon>
      </v-btn>
    </template>
    <v-card elevation="2">
      <v-card-title>
        <span class="accent--text body-1 font-weight-bold"
          >篩選節電排行條件</span
        >
        <v-spacer></v-spacer>
        <v-btn elevation="0" icon @click="filterMenu = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="query">
        <v-select
          v-model="query.type"
          :items="schoolType"
          placeholder="選擇比較對象"
          outlined
          dense
          item-text="name"
          item-value="key"
          multiple
        >
          <template v-slot:selection="{ index }">
            <span v-if="index === 1" class="grey--text"> 選擇比較對象 </span>
          </template>
        </v-select>
        <div>
          <v-chip
            v-for="(selected, selectedIndex) in query.type"
            :key="selectedIndex"
            close
            close-icon="mdi-close-circle"
            color="blue lighten-3"
            text-color="accent"
            class="mr-1 mb-1"
            @click:close="query.type.splice(selectedIndex, 1)"
          >
            {{ selected | showName(schoolType) }}
          </v-chip>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          color="primary"
          @click="
            commit()
            filterMenu = false
          "
        >
          確認
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          groupId: null
        }
      }
    }
  },
  filters: {
    showName: function (value, items) {
      if (!value) return null
      const type = items.find((item) => item.key === value)
      return type ? type.name : null
    }
  },
  data: function () {
    return {
      filterMenu: false,
      query: JSON.parse(JSON.stringify(this.value))
    }
  },
  computed: {
    ...mapGetters({
      schoolType: 'config/schoolType'
    })
  },
  watch: {
    value: {
      deep: true,
      handler: function (val, oldVal) {
        this.query = JSON.parse(JSON.stringify(val))
      }
    }
  },
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {
    commit() {
      this.$emit('input', this.query)
      this.$emit('change', this.query)
    },
    resetQuery() {
      this.query = JSON.parse(JSON.stringify(this.value))
    }
  }
}
</script>

<style lang="scss" scoped>
.sort-list::v-deep {
  .item-header {
    color: #ddd;
  }
  .item-body {
    max-height: 300px;
    overflow-y: auto;
  }

  @media (min-width: 960px) {
    .item-body {
      max-height: 768px;
    }
  }

  .v-list-item:not(.item-header) {
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    .v-list-item__title {
      color: var(--v-accent-darken1);
      font-weight: bold;
    }
  }

  .item-sort-1 {
    background: linear-gradient(65deg, #2fd9ff 0%, #00ddc9 50%);
    .v-avatar {
      background-color: var(--v-warning-lighten2) !important;
      border-color: var(--v-warning-lighten2) !important;
    }
  }

  .item-sort-2,
  .item-sort-3 {
    .v-avatar {
      background-color: var(--v-primary-base) !important;
      border-color: var(--v-primary-base) !important;
    }
  }

  .v-avatar {
    background-color: var(--v-ems-pale-grey-darken3) !important;
    border-color: var(--v-ems-pale-grey-darken3) !important;
  }
}
</style>
