<template>
  <div>
    <div class="AQI-box" v-if="value">
      <div class="AQI-Bar"></div>
      <v-row justify="space-between" class="mt-2">
        <v-col cols="1" class="pa-0 text-center">0</v-col>
        <v-col cols="1" class="pa-0 text-center">50</v-col>
        <v-col cols="1" class="pa-0 text-center">100</v-col>
        <v-col cols="1" class="pa-0 text-center">150</v-col>
        <v-col cols="1" class="pa-0 text-center">200</v-col>
        <v-col cols="1" class="pa-0 text-center">250</v-col>
        <v-col cols="1" class="pa-0 text-center">300</v-col>
        <v-col cols="1" class="pa-0 text-center">350</v-col>
        <v-col cols="1" class="pa-0 text-center">400</v-col>
      </v-row>
      <div class="AQI-select-square" v-bind:style="squareStyleObject"></div>
    </div>
    <div v-else>
      <span> -- </span>
    </div>
  </div>
</template>

<script>
export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    value: {
      type: [Number, String]
    }
  },
  data: function () {
    return {}
  },
  computed: {
    squareStyleObject() {
      return {
        left: `calc(${(Number(this.value) / 400) * 100}% - 13px)`
      }
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
*::v-deep {
  .AQI-box {
    position: relative;
    width: 300px;
    .AQI-Bar {
      width: 100%;
      height: 5px;
      background: linear-gradient(
        90deg,
        #03de68 0%,
        #03de68 0%,
        #f8ce1b 20%,
        #fc5a1f 40%,
        #b110ce 60%,
        #641089 80%,
        #420355 100%
      );
    }
    .AQI-select-square {
      width: 25px;
      height: 12px;
      border: 2px solid #fff;
      position: absolute;
      top: -3px;
    }
  }
}
</style>
